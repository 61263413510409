body {
  margin: 0;
}

.menu-card {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.menu-card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.profileMenu-card-close,
.profileMenu-card-open {
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.profileMenu-card-close:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.profileMenu-card-open {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
}

.row-slide-in {
  animation: slideIn 0.5s ease-in-out;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
